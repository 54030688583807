const projectData = [
    {
        id: 1,
        codigo:'CV67',
        nombreProyecto: 'FRANCISCO CASTILLO',
        direccion:'Lo Aguila S/N lote D.Parcelacion El Pajal',
        etapa:'Operación',
        subEtapa:'Sistema operativo y con inyecciones reconocidas',
        image: 'https://fluxsolar.cl/imagenesflux/f9a900d231511ef3dc231648a28cc125.jpg',
    },
    {
        id: 2,
        codigo:'CV67',
        nombreProyecto: 'RODRIGO NAVAS',
        direccion:'Aerocardal',
        etapa:'Ejecución',
        subEtapa:'En Ejecución',
        image: 'https://fluxsolar.cl/imagenesflux/0f9e02bbdcaa9e0834bf7f58d8614cd2.jpg',
    },
    {
        id: 3,
        codigo:'CV67',
        nombreProyecto: 'Proyecto Residencial Eduardo Jacoby',
        direccion:'Costa Rica 8887',
        etapa:'Operación',
        subEtapa:'Preparación documentación Notificación de Conexión',
        image: 'https://fluxsolar.cl/imagenesflux/d9fbea3e013178a80c2995f46cae2505.jpg',
    },
    {
        id: 4,
        codigo:'CV67',
        nombreProyecto: 'Waltmart ESCO',
        direccion:'Direccion 1234',
        etapa:'Planificación',
        subEtapa:'Coordinación Fecha de instalación',
        image: 'https://fluxsolar.cl/imagenesflux/215bb8ef0bdedecf816e1546d6be8544.jpg',
    }
];

export { projectData };