<template>
  <div class="row">
    <div class="col-12">
      <h4 class="card-title">Crear Lugar Instalación</h4>
      <form-wizard color="#556ee6" next-button-text="Siguiente" ref="wizard">
        <tab-content icon="mdi mdi-clipboard-list-outline">
          <CrearLugarInstalacion
            @on-validate="onStepValidate"
            @cerrarWizar="cerrarWizar"
          />
        </tab-content>
        <tab-content icon="mdi mdi-cog-outline">
          <CrearInsformacionTecnica
            @on-validate="onStepValidate"
            :lugarInstalacion="lugarInstalacion"
            @cerrarWizar="cerrarWizar"
          />
        </tab-content>
        <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <h2 class="mt-0">
                  <i class="mdi mdi-check-all"></i>
                </h2>
                <h3 class="mt-0">
                  ¡¡¡Listo, Lugar de instalación creado!!!
                </h3>

                <p class="w-75 mb-2 mx-auto">
                  Ha creado el lugar de instalación con la información base
                  necesaria, ahora debe ingresar a los diferentes módulos y
                  llenar la información faltante
                </p>
              </div>
            </div>
          </div>
        </tab-content>
        <template slot="footer" slot-scope="props">
          <div class="text-center">
            <wizard-button
              v-if="nextButtonVisible"
              @click.native="props.nextTab()"
              class="btn btn-theme"
            >
              {{ buttonText(props.activeTabIndex) }}
            </wizard-button>
            <wizard-button
              v-if="props.isLastStep"
              @click.native="cerrarWizar"
              class="wizard-footer-right finish-button btn btn-theme"
              >Finalizar</wizard-button
            >
          </div>
        </template>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import CrearLugarInstalacion from "@/components/widgets/lugarInstalacion/crear-lugar-instalacion";
import CrearInsformacionTecnica from "@/components/widgets/informacionTecnica/crear-informacion-tecnica";

export default {
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    CrearLugarInstalacion,
    CrearInsformacionTecnica,
  },
  data() {
    return {
      nextButtonVisible: false,
      lugarInstalacion: {},
    };
  },
  mounted() {},
  methods: {
    onStepValidate(lugarInstalacion) {
      console.log("onStepValidate");
      this.lugarInstalacion = lugarInstalacion;
      this.$refs.wizard.nextTab();
    },
    cerrarWizar() {
      this.$emit("cerrarWizar");
    },
  },
};
</script>
