<template>
  <form @submit.prevent="crearLugarInstalacionProyecto">
    <loading-flux :value="loading"></loading-flux>
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="nombreProyecto">Nombre Lugar Instalación*</label>
          <input
            id="nombreProyecto"
            v-model="$v.form.nombreProyecto.$model"
            type="text"
            class="form-control"
            placeholder="Nombre Lugar Instalación"
            :class="{
              'is-invalid': submitted && $v.form.nombreProyecto.$invalid,
            }"
          />
          <div
            v-if="submitted && $v.form.nombreProyecto.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="montoProyecto">Monto</label>
          <input
            id="montoProyecto"
            v-model="$v.form.montoProyecto.$model"
            type="text"
            class="form-control"
            placeholder="Monto Proyecto"
            :class="{
              'is-invalid': $v.form.montoProyecto.$invalid && submitted,
            }"
          />

          <div
            class="invalid-feedback"
            v-if="submitted && $v.form.montoProyecto.$invalid"
          >
            El campo debe ser numérico
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="tipoProyecto">Tipo Proyecto*</label>
          <multiselect
            v-model="$v.form.tipoProyecto.$model"
            :options="tipoProyectos"
            placeholder="Seleccionar Tipo Proyecto"
            value="tipo_proyecto_id"
            label="nombre_tipo_proyecto"
            :searchable="true"
            :loading="tipoProyectosCargando"
            open-direction="bottom"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.tipoProyecto.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.tipoProyecto.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="estado">Estado*</label>
          <multiselect
            v-model="$v.form.estado.$model"
            :options="estados"
            placeholder="Seleccionar Estado"
            value="value"
            label="label"
            :searchable="true"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.estado.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.estado.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="etapaProyecto">Etapa Proyecto*</label>
          <multiselect
            v-model="$v.form.etapaProyecto.$model"
            :options="etapasProyecto"
            placeholder="Seleccionar Etapa"
            value="etapa_proyecto_id"
            label="nombre_etapa_proyecto"
            :searchable="true"
            :loading="etapasProyectoCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.etapaProyecto.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.etapaProyecto.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="subEtapaProyecto">Sub Etapa Proyecto*</label>
          <multiselect
            v-model="$v.form.subEtapaProyecto.$model"
            :options="subEtapasProyecto"
            placeholder="Seleccionar Sub Etapa"
            value="sub_etapa_proyecto_id"
            label="nombre_sub_etapa_proyecto"
            :searchable="true"
            :loading="subEtapasProyectoCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.subEtapaProyecto.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.subEtapaProyecto.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="tipoVenta">Tipo Venta*</label>
          <multiselect
            v-model="$v.form.tipoVenta.$model"
            :options="tipoVentas"
            placeholder="Seleccionar Tipo Venta"
            value="tipo_venta_id"
            label="nombre_tipo_venta"
            :searchable="true"
            :loading="tipoVentasCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.tipoVenta.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.tipoVenta.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="direccion">Dirección*</label>
          <input
            id="direccion"
            v-model="$v.form.direccion.$model"
            type="text"
            class="form-control"
            placeholder="Dirección"
            :class="{
              'is-invalid': submitted && $v.form.direccion.$invalid,
            }"
          />
          <div
            v-if="submitted && $v.form.direccion.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="region">Region*</label>
          <multiselect
            @input="obtenerComunasRegionProyecto()"
            v-model="$v.form.region.$model"
            :options="regiones"
            placeholder="Seleccionar Region"
            value="codigo"
            label="nombre"
            :searchable="true"
            :loading="regionesCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.region.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.region.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="comuna">Comuna*</label>
          <multiselect
            v-model="$v.form.comuna.$model"
            :options="comunasRegion"
            placeholder="Seleccionar Comuna"
            value="codigo"
            label="nombre"
            :searchable="true"
            class="helo comuna"
            :loading="comunasRegionCargando"
            :class="{
              'is-invalid': submitted && $v.form.comuna.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.comuna.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="zona">Zona*</label>
          <multiselect
            v-model="$v.form.zona.$model"
            :options="zonas"
            placeholder="Seleccionar Zona"
            value="value"
            label="label"
            :searchable="true"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.zona.$invalid,
            }"
          ></multiselect>
          <div
            v-if="submitted && $v.form.zona.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6" v-if="true">
        <div class="mb-3">
          <label for="latitud">Latitud</label>
          <input
            id="latitud"
            v-model="$v.form.latitud.$model"
            type="text"
            class="form-control"
            placeholder="Latitud"
            :class="{
              'is-invalid': submitted && $v.form.latitud.$invalid,
            }"
          />
          <div
            v-if="submitted && $v.form.latitud.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6" v-if="true">
        <div class="mb-3">
          <label for="longitud">Longitud</label>
          <input
            id="longitud"
            v-model="$v.form.longitud.$model"
            type="text"
            class="form-control"
            placeholder="Longitud"
            :class="{
              'is-invalid': submitted && $v.form.longitud.$invalid,
            }"
          />
          <div
            v-if="submitted && $v.form.longitud.$invalid"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
    </div>
    <div class="text-end mt-3">
      <b-button variant="light" @click="cerrarWizar">Cerrar</b-button>
      <b-button type="submit" variant="success" class="ms-1"
        >Siguiente</b-button
      >
    </div>
  </form>
</template>

<script>
import {
  lugarInstalacionMethods,
  lugarInstalacionComputed,
} from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { tipoVentaMethods, tipoVentaComputed } from "@/state/helpers";
import { etapaProyectoMethods, etapaProyectoComputed } from "@/state/helpers";
import {
  subEtapaProyectoMethods,
  subEtapaProyectoComputed,
} from "@/state/helpers";
import { localizacionMethods, localizacionComputed } from "@/state/helpers";
import { proyectoComputed } from "@/state/helpers";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  components: { Multiselect },
  data() {
    return {
      loading: false,
      lugarInstalacion: {},
      mostrarModal: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      submitted: false,

      tipoProyectos: [],
      tipoVentas: [],
      etapasProyecto: [],
      subEtapasProyecto: [],
      regiones: [],
      comunasRegion: [],
      zonas: [
        { value: "Norte", label: "Norte" },
        { value: "Centro", label: "Centro" },
        { value: "Sur", label: "Sur" },
        { value: "Insular", label: "Insular" },
      ],
      estados: [
        { value: "Activo", label: "Activo" },
        { value: "Cerrado", label: "Cerrado" },
        { value: "Detenido", label: "Detenido" },
        { value: "Nuevo", label: "Nuevo" },
        { value: "Terminado", label: "Terminado" },
      ],
      tipoProyectosCargando: false,
      tipoVentasCargando: false,
      etapasProyectoCargando: false,
      subEtapasProyectoCargando: false,
      regionesCargando: false,
      comunasRegionCargando: false,

      form: {
        nombreProyecto: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        etapaProyecto: null,
        subeEtapaProyecto: null,
        direccion: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
      },
      imagenParaSubir: null,
    };
  },
  validations: {
    form: {
      nombreProyecto: { required },
      montoProyecto: {},
      tipoProyecto: {},
      tipoVenta: {},
      estado: {},
      etapaProyecto: {},
      subEtapaProyecto: { required },
      direccion: { required },
      region: { required },
      comuna: { required },
      latitud: {},
      longitud: {},
      zona: {},
    },
  },
  mounted() {
    this.resetform();
    this.obtenerTipoProyectosProyecto();
    this.obtenerTipoVentasProyecto();
    this.obtenerEtapaProyectoProyecto();
    this.obtenerSubEtapaProyectoProyecto();
    this.obtenerRegionesProyecto();
  },
  methods: {
    ...lugarInstalacionMethods,
    ...tipoProyectoMethods,
    ...tipoVentaMethods,
    ...etapaProyectoMethods,
    ...subEtapaProyectoMethods,
    ...localizacionMethods,
    obtenerTipoProyectosProyecto() {
      this.tipoProyectosCargando = true;
      this.obtenerTipoProyectos()
        .then((res) => {
          this.tipoProyectos = res.body;
          this.tipoProyectosCargando = false;
        })
        .catch((error) => {
          this.tipoProyectosCargando = false;
        });
    },
    obtenerTipoVentasProyecto() {
      this.tipoVentasCargando = true;
      this.obtenerTipoVentas()
        .then((res) => {
          this.tipoVentas = res.body;
          this.tipoVentasCargando = false;
        })
        .catch((error) => {
          this.tipoVentasCargando = false;
        });
    },
    obtenerEtapaProyectoProyecto() {
      this.etapasProyectoCargando = true;
      this.obtenerEtapasProyecto()
        .then((res) => {
          this.etapasProyecto = res.body;
          this.etapasProyectoCargando = false;
        })
        .catch((error) => {
          this.etapasProyectoCargando = false;
        });
    },
    obtenerSubEtapaProyectoProyecto() {
      this.subEtapasProyectoCargando = true;
      this.obtenerSubEtapasProyecto()
        .then((res) => {
          this.subEtapasProyecto = res.body;
          this.subEtapasProyectoCargando = false;
        })
        .catch((error) => {
          this.subEtapasProyectoCargando = false;
        });
    },
    obtenerRegionesProyecto() {
      this.regionesCargando = true;
      this.obtenerRegiones()
        .then((res) => {
          this.regiones = res.body;

          this.regionesCargando = false;
        })
        .catch((error) => {
          this.regionesCargando = false;
        });
    },
    obtenerComunasRegionProyecto() {
      this.comunasRegionCargando = true;
      this.$v.form.comuna.$model = null;
      let codigoRegion = this.$v.form.region.$model.codigo;
      this.obtenerComunasRegion(codigoRegion)
        .then((res) => {
          this.comunasRegion = res.body;
          this.comunasRegionCargando = false;
        })
        .catch((error) => {
          this.comunasRegionCargando = false;
        });
    },
    cerrarWizar() {
      this.$emit("cerrarWizar");
    },

    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        this.imagenParaSubir = file;
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    crearLugarInstalacionProyecto() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.submitted = true;
        this.$v.form.$touch();

        if (this.$v.form.$invalid) {
          this.loading = false;
          resolve(this.$v.form.$invalid);
          return;
        }

        const codigo_lugar_instalacion = "ssssssss";
        const codigo_proyecto = this.$route.params.codigoProyecto;

        const nombre_proyecto = this.$v.form.nombreProyecto.$model;
        const monto_proyecto =
          this.$v.form.montoProyecto.$model != null
            ? this.$v.form.montoProyecto.$model
            : null;
        const tipo_proyecto_id =
          this.$v.form.tipoProyecto.$model != null
            ? this.$v.form.tipoProyecto.$model.tipo_proyecto_id
            : null;
        const nombre_tipo_proyecto =
          this.$v.form.tipoProyecto.$model != null
            ? this.$v.form.tipoProyecto.$model.nombre_tipo_proyecto
            : null;

        const tipo_venta_id =
          this.$v.form.tipoVenta.$model != null
            ? this.$v.form.tipoVenta.$model.tipo_venta_id
            : null;
        const nombre_tipo_venta =
          this.$v.form.tipoVenta.$model != null
            ? this.$v.form.tipoVenta.$model.nombre_tipo_venta
            : null;

        const estado =
          this.$v.form.estado.$model != null
            ? this.$v.form.estado.$model.value
            : null;

        const etapa_proyecto_id =
          this.$v.form.etapaProyecto.$model != null
            ? this.$v.form.etapaProyecto.$model.etapa_proyecto_id
            : null;

        const nombre_etapa_proyecto =
          this.$v.form.etapaProyecto.$model != null
            ? this.$v.form.etapaProyecto.$model.nombre_etapa_proyecto
            : null;

        const sub_etapa_proyecto_id =
          this.$v.form.subEtapaProyecto.$model != null
            ? this.$v.form.subEtapaProyecto.$model.sub_etapa_proyecto_id
            : null;

        const nombre_sub_etapa_proyecto =
          this.$v.form.subEtapaProyecto.$model != null
            ? this.$v.form.subEtapaProyecto.$model.nombre_sub_etapa_proyecto
            : null;

        const direccion =
          this.$v.form.direccion.$model != null
            ? this.$v.form.direccion.$model
            : null;

        const codigo_comuna =
          this.$v.form.comuna.$model != null
            ? this.$v.form.comuna.$model.codigo
            : null;

        const nombre_comuna =
          this.$v.form.comuna.$model != null
            ? this.$v.form.comuna.$model.nombre
            : null;

        const codigo_region =
          this.$v.form.region.$model != null
            ? this.$v.form.region.$model.codigo
            : null;
        const nombre_region =
          this.$v.form.region.$model != null
            ? this.$v.form.region.$model.nombre
            : null;
        const latitud =
          this.$v.form.latitud.$model != null
            ? this.$v.form.latitud.$model
            : null;
        const longitud =
          this.$v.form.longitud.$model != null
            ? this.$v.form.longitud.$model
            : null;
        const zona =
          this.$v.form.zona.$model != null
            ? this.$v.form.zona.$model.value
            : null;

        let formData = new FormData();
        formData.append("file", this.imagenParaSubir);

        formData.append(
          "lugar_instalacion",
          JSON.stringify({
            codigo_lugar_instalacion,
            codigo_proyecto,
            nombre_proyecto,
            monto_proyecto,
            tipo_proyecto_id,
            nombre_tipo_proyecto,
            estado,
            tipo_venta_id,
            nombre_tipo_venta,
            etapa_proyecto_id,
            nombre_etapa_proyecto,
            sub_etapa_proyecto_id,
            nombre_sub_etapa_proyecto,
            direccion,
            codigo_comuna,
            nombre_comuna,
            codigo_region,
            nombre_region,
            latitud,
            longitud,
            zona,
          })
        );

        this.crearLugarInstalacionForm(formData)
          .then((res) => {
            if (res.status == 201) {
              this.mensaje.variant = "success";
              this.mensaje.texto = "Lugar Instalación creado correctamente!!!";

              this.lugarInstalacion = {
                codigo_lugar_instalacion: res.body.codigo_lugar_instalacion,
                codigo_proyecto: res.body.codigo_proyecto,
              };

              this.$emit("on-validate", this.lugarInstalacion);
            }

            if (res.status == 208) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "El lugar instalación ya existe!!!";
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });
        this.submitted = false;
      });
    },
    resetform() {
      this.form = {
        nombreProyecto: null,
        montoProyecto: null,
        tipoProyecto: null,
        tipoVenta: null,
        estado: null,
        etapaProyecto: null,
        subEtapaProyecto: null,
        direccion: null,
        region: null,
        comuna: null,
        latitud: null,
        longitud: null,
        zona: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
