var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"potenciaPeak"}},[_vm._v("Potencia Peak [W]")]),_c('div',{staticClass:"input-group mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.potenciaPeak.$model),expression:"$v.form.potenciaPeak.$model"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.potenciaPeak.$error,
            },attrs:{"id":"potenciaPeak","type":"number","step":"0.001","placeholder":"Potencia Peak"},domProps:{"value":(_vm.$v.form.potenciaPeak.$model)},on:{"keypress":_vm.validarNumero,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.potenciaPeak, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.potenciaPeak.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"potenciaAC"}},[_vm._v("Potencia AC [W]")]),_c('div',{staticClass:"input-group mb-3"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.potenciaAC.$model),expression:"$v.form.potenciaAC.$model"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.potenciaAC.$error,
            },attrs:{"id":"potenciaAC","step":"0.001","type":"number","placeholder":"Potencia AC"},domProps:{"value":(_vm.$v.form.potenciaAC.$model)},on:{"keypress":_vm.validarNumero,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.potenciaAC, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.potenciaAC.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"capacidadBaterias"}},[_vm._v("Capacidad Baterias [KWh]")]),_c('div',{staticClass:"input-group mb-3"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.capacidadBaterias.$model),expression:"$v.form.capacidadBaterias.$model"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.capacidadBaterias.$error,
            },attrs:{"id":"capacidadBaterias","step":"0.001","type":"number","placeholder":"Capacidad Bateria"},domProps:{"value":(_vm.$v.form.capacidadBaterias.$model)},on:{"keypress":_vm.validarNumero,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.capacidadBaterias, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.capacidadBaterias.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"tipoInstalacion"}},[_vm._v("Tipo Instalación*")]),_c('multiselect',{staticClass:"helo",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.tipoInstalacion.$error,
          },attrs:{"options":_vm.tipoInstalaciones,"placeholder":"Seleccionar Tipo Proyecto","value":"tipo_instalacion_id","label":"nombre_tipo_instalacion","searchable":true,"loading":_vm.tipoInstalacionesCargando},model:{value:(_vm.$v.form.tipoInstalacion.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.tipoInstalacion, "$model", $$v)},expression:"$v.form.tipoInstalacion.$model"}}),(_vm.submitted && !_vm.$v.form.tipoInstalacion.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"faseInstalacion"}},[_vm._v("Fase Instalación*")]),_c('multiselect',{staticClass:"helo",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.faseInstalacion.$error,
          },attrs:{"options":_vm.fasesInstalacion,"placeholder":"Seleccionar Fase","value":"value","label":"label","searchable":true,"loading":_vm.fasesInstalacionCargando},model:{value:(_vm.$v.form.faseInstalacion.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.faseInstalacion, "$model", $$v)},expression:"$v.form.faseInstalacion.$model"}}),(_vm.submitted && !_vm.$v.form.faseInstalacion.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"tipoCliente"}},[_vm._v("Tipo Cliente*")]),_c('multiselect',{staticClass:"helo",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.tipoCliente.$error,
          },attrs:{"options":_vm.tipoClientes,"placeholder":"Seleccionar Tipo Cliente","value":"tipo_cliente_id","label":"nombre_tipo_cliente","searchable":true,"loading":_vm.tipoClientesCargando},model:{value:(_vm.$v.form.tipoCliente.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.tipoCliente, "$model", $$v)},expression:"$v.form.tipoCliente.$model"}}),(_vm.submitted && !_vm.$v.form.tipoCliente.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"normativa"}},[_vm._v("Normativa*")]),_c('multiselect',{staticClass:"helo",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.normativa.$error,
          },attrs:{"options":_vm.normativas,"placeholder":"Seleccionar Normativa","value":"normativa_id","label":"nombre_normativa","searchable":true,"loading":_vm.normativasCargando},model:{value:(_vm.$v.form.normativa.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.normativa, "$model", $$v)},expression:"$v.form.normativa.$model"}}),(_vm.submitted && !_vm.$v.form.normativa.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"clienteElectrico"}},[_vm._v("Cliente Electrico*")]),_c('multiselect',{staticClass:"helo",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.clienteElectrico.$error,
          },attrs:{"options":_vm.clientesElectrico,"placeholder":"Seleccionar Cliente Electrico","value":"value","label":"label","searchable":true,"loading":_vm.clientesElectricoCargando},model:{value:(_vm.$v.form.clienteElectrico.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.clienteElectrico, "$model", $$v)},expression:"$v.form.clienteElectrico.$model"}}),(_vm.submitted && !_vm.$v.form.clienteElectrico.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()],1)]),(false)?_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"appFlexEnergetica"}},[_vm._v("App Flex Energetica*")]),_c('multiselect',{staticClass:"helo",class:{
            'is-invalid': _vm.submitted && _vm.$v.form.appFlexEnergetica.$error,
          },attrs:{"options":_vm.appFlexEnergeticas,"placeholder":"Seleccionar App Flex Energetica","value":"app_flex_energetica_id","label":"nombre_app_flex_energetica","searchable":true,"loading":_vm.clientesElectricoCargando},model:{value:(_vm.$v.form.appFlexEnergetica.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.appFlexEnergetica, "$model", $$v)},expression:"$v.form.appFlexEnergetica.$model"}}),(_vm.submitted && !_vm.$v.form.appFlexEnergetica.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"capacidadEmpalme"}},[_vm._v("Capacidad Empalme [A]")]),_c('div',{staticClass:"input-group mb-3"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.capacidadEmpalme.$model),expression:"$v.form.capacidadEmpalme.$model"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.capacidadEmpalme.$error,
            },attrs:{"id":"capacidadEmpalme","type":"number","step":"0.001","placeholder":"Capacidad Empalme"},domProps:{"value":(_vm.$v.form.capacidadEmpalme.$model)},on:{"keypress":_vm.validarNumero,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.capacidadEmpalme, "$model", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.capacidadEmpalme.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()])])]),_c('div',{staticClass:"text-end mt-3"},[_c('b-button',{staticClass:"ms-1",attrs:{"type":"submit","variant":"success"}},[_vm._v("Crear")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon3"}},[_vm._v("W")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon3"}},[_vm._v("W")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon3"}},[_vm._v("KWh")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon3"}},[_vm._v("A")])])}]

export { render, staticRenderFns }