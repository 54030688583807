<template>
  <form @submit.prevent="submit">
    <loading-flux :value="loading"></loading-flux>

    <div class="row">
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="potenciaPeak">Potencia Peak [W]</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">W</span>
            </div>
            <input
              id="potenciaPeak"
              v-model="$v.form.potenciaPeak.$model"
              type="number"
              step="0.001"
              class="form-control"
              placeholder="Potencia Peak"
              :class="{
                'is-invalid': submitted && $v.form.potenciaPeak.$error,
              }"
              @keypress="validarNumero"
            />
          </div>
          <div
            v-if="submitted && !$v.form.potenciaPeak.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="potenciaAC">Potencia AC [W]</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">W</span>
            </div>
            <input
              id="potenciaAC"
              v-model="$v.form.potenciaAC.$model"
              step="0.001"
              type="number"
              class="form-control"
              placeholder="Potencia AC"
              :class="{
                'is-invalid': submitted && $v.form.potenciaAC.$error,
              }"
              @keypress="validarNumero"
            />
          </div>
          <div
            v-if="submitted && !$v.form.potenciaAC.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="capacidadBaterias">Capacidad Baterias [KWh]</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">KWh</span>
            </div>
            <input
              id="capacidadBaterias"
              v-model="$v.form.capacidadBaterias.$model"
              step="0.001"
              type="number"
              class="form-control"
              placeholder="Capacidad Bateria"
              :class="{
                'is-invalid': submitted && $v.form.capacidadBaterias.$error,
              }"
              @keypress="validarNumero"
            />
          </div>
          <div
            v-if="submitted && !$v.form.capacidadBaterias.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="tipoInstalacion">Tipo Instalación*</label>
          <multiselect
            v-model="$v.form.tipoInstalacion.$model"
            :options="tipoInstalaciones"
            placeholder="Seleccionar Tipo Proyecto"
            value="tipo_instalacion_id"
            label="nombre_tipo_instalacion"
            :searchable="true"
            :loading="tipoInstalacionesCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.tipoInstalacion.$error,
            }"
          ></multiselect>
          <div
            v-if="submitted && !$v.form.tipoInstalacion.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="faseInstalacion">Fase Instalación*</label>
          <multiselect
            v-model="$v.form.faseInstalacion.$model"
            :options="fasesInstalacion"
            placeholder="Seleccionar Fase"
            value="value"
            label="label"
            :searchable="true"
            :loading="fasesInstalacionCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.faseInstalacion.$error,
            }"
          ></multiselect>
          <div
            v-if="submitted && !$v.form.faseInstalacion.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="tipoCliente">Tipo Cliente*</label>
          <multiselect
            v-model="$v.form.tipoCliente.$model"
            :options="tipoClientes"
            placeholder="Seleccionar Tipo Cliente"
            value="tipo_cliente_id"
            label="nombre_tipo_cliente"
            :searchable="true"
            :loading="tipoClientesCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.tipoCliente.$error,
            }"
          ></multiselect>
          <div
            v-if="submitted && !$v.form.tipoCliente.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="normativa">Normativa*</label>
          <multiselect
            v-model="$v.form.normativa.$model"
            :options="normativas"
            placeholder="Seleccionar Normativa"
            value="normativa_id"
            label="nombre_normativa"
            :searchable="true"
            :loading="normativasCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.normativa.$error,
            }"
          ></multiselect>
          <div
            v-if="submitted && !$v.form.normativa.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="clienteElectrico">Cliente Electrico*</label>
          <multiselect
            v-model="$v.form.clienteElectrico.$model"
            :options="clientesElectrico"
            placeholder="Seleccionar Cliente Electrico"
            value="value"
            label="label"
            :searchable="true"
            :loading="clientesElectricoCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.clienteElectrico.$error,
            }"
          ></multiselect>
          <div
            v-if="submitted && !$v.form.clienteElectrico.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>

      <div class="col-lg-6" v-if="false">
        <div class="mb-3">
          <label for="appFlexEnergetica">App Flex Energetica*</label>
          <multiselect
            v-model="$v.form.appFlexEnergetica.$model"
            :options="appFlexEnergeticas"
            placeholder="Seleccionar App Flex Energetica"
            value="app_flex_energetica_id"
            label="nombre_app_flex_energetica"
            :searchable="true"
            :loading="clientesElectricoCargando"
            class="helo"
            :class="{
              'is-invalid': submitted && $v.form.appFlexEnergetica.$error,
            }"
          ></multiselect>
          <div
            v-if="submitted && !$v.form.appFlexEnergetica.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="capacidadEmpalme">Capacidad Empalme [A]</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">A</span>
            </div>
            <input
              id="capacidadEmpalme"
              v-model="$v.form.capacidadEmpalme.$model"
              type="number"
              step="0.001"
              class="form-control"
              placeholder="Capacidad Empalme"
              :class="{
                'is-invalid': submitted && $v.form.capacidadEmpalme.$error,
              }"
              @keypress="validarNumero"
            />
          </div>
          <div
            v-if="submitted && !$v.form.capacidadEmpalme.required"
            class="invalid-feedback"
          >
            El campo es obligatorio.
          </div>
        </div>
      </div>
    </div>
    <div class="text-end mt-3">
      <!-- <b-button variant="light" @click="cerrarWizar">Cerrar</b-button> -->
      <b-button type="submit" variant="success" class="ms-1">Crear</b-button>
    </div>
  </form>
</template>

<script>
import {
  informacionTecnicaMethods,
  informacionTecnicaComputed,
} from "@/state/helpers";
import { tipoClienteMethods, tipoClienteComputed } from "@/state/helpers";
import {
  tipoInstalacionMethods,
  tipoInstalacionComputed,
} from "@/state/helpers";
import { normativaMethods, normativaComputed } from "@/state/helpers";
import {
  appFlexEnergeticaMethods,
  appFlexEnergeticaComputed,
} from "@/state/helpers";
import { proyectoComputed } from "@/state/helpers";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  components: {
    Multiselect,
  },
  props: {
    lugarInstalacion: Object,
  },
  data() {
    return {
      loading: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      informacionTecnica: {},
      submitted: false,
      mostrarModal: false,
      tipoInstalaciones: [],
      fasesInstalacion: [
        { value: "MONOFASICO", label: "MONOFASICO" },
        { value: "TRIFASICO", label: "TRIFASICO" },
        { value: "NO APLICA", label: "NO APLICA" },
      ],
      tipoClientes: [],
      clientesElectrico: [
        { value: "Cliente Regulado", label: "Cliente Regulado" },
        { value: "Cliente Libre", label: "Cliente Libre" },
        {
          value: "Desconectado/Aislado",
          label: "Desconectado/Aislado",
        },
      ],

      normativas: [],
      appFlexEnergeticas: [],

      tipoInstalacionesCargando: false,
      fasesInstalacionCargando: false,
      tipoClientesCargando: false,
      clientesElectricoCargando: false,
      normativasCargando: false,
      appFlexEnergeticasCargando: false,
      form: {
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      },
    };
  },
  validations: {
    form: {
      potenciaPeak: {},
      potenciaAC: {},
      capacidadBaterias: {},
      tipoInstalacion: { required },
      faseInstalacion: { required },
      tipoCliente: { required },
      clienteElectrico: { required },
      normativa: { required },
      appFlexEnergetica: {},
      capacidadEmpalme: {},
    },
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    // this.obtenerInformacionTecnicaProyecto();
    this.obtenerTipoInstalacionesProyecto();
    this.obtenerTipoClientesProyecto();
    this.obtenerNormativasProyecto();
    this.obtenerAppFlexEnergeticasProyecto();
  },
  methods: {
    ...informacionTecnicaMethods,
    ...tipoClienteMethods,
    ...tipoInstalacionMethods,
    ...normativaMethods,
    ...appFlexEnergeticaMethods,
    validarNumero(event) {
      if (event.keyCode < 45 || event.keyCode > 57) {
        event.returnValue = false;
      }
      if (event.keyCode == 45) {
        event.returnValue = false;
      }
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    cerrarWizar() {
      this.$emit("cerrarWizar");
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
    },
    abrirModalEditar() {
      this.obtenerTipoInstalacionesProyecto();
      this.obtenerTipoClientesProyecto();
      this.obtenerNormativasProyecto();
      this.obtenerAppFlexEnergeticasProyecto();

      this.$v.form.potenciaPeak.$model =
        this.informacionTecnica.potencia_peak != null
          ? this.informacionTecnica.potencia_peak
          : null;
      this.$v.form.potenciaAC.$model =
        this.informacionTecnica.potencia_ac != null
          ? this.informacionTecnica.potencia_ac
          : null;
      this.$v.form.capacidadBaterias.$model =
        this.informacionTecnica.capacidad_baterias != null
          ? this.informacionTecnica.capacidad_baterias
          : null;
      this.$v.form.capacidadEmpalme.$model =
        this.informacionTecnica.capacidad_empalme != null
          ? this.informacionTecnica.capacidad_empalme
          : null;

      this.$v.form.faseInstalacion.$model = {
        value: this.informacionTecnica.fase_instalacion,
        label: this.informacionTecnica.fase_instalacion,
      };
      this.$v.form.clienteElectrico.$model = {
        value: this.informacionTecnica.cliente_electrico,
        label: this.informacionTecnica.cliente_electrico,
      };
      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    obtenerInformacionTecnicaProyecto() {
      this.obtenerInformacionTecnica({
        codigoProyecto: this.codigoProyecto,
        codigoLugarInstalacion: this.codigoLugarInstalacion,
      })
        .then((res) => {
          this.informacionTecnica = res.body;
        })
        .catch((error) => {});
    },
    obtenerNormativasProyecto() {
      this.normativasCargando = true;
      this.obtenerNormativas()
        .then((res) => {
          this.normativas = res.body;
          // this.$v.form.normativa.$model = {
          //     normativa_id: this.informacionTecnica.normativa_id,
          //     nombre_normativa: this.informacionTecnica
          //         .nombre_normativa,
          // };
          this.normativasCargando = false;
        })
        .catch((error) => {
          this.normativasCargando = false;
        });
    },
    obtenerTipoInstalacionesProyecto() {
      this.tipoInstalacionesCargando = true;
      this.obtenerTipoInstalaciones()
        .then((res) => {
          this.tipoInstalaciones = res.body;
          // this.$v.form.tipoInstalacion.$model = {
          //     tipo_instalacion_id: this.informacionTecnica
          //         .tipo_instalacion_id,
          //     nombre_tipo_instalacion: this.informacionTecnica
          //         .nombre_tipo_instalacion,
          // };
          this.tipoInstalacionesCargando = false;
        })
        .catch((error) => {
          this.tipoInstalacionesCargando = false;
        });
    },
    obtenerTipoClientesProyecto() {
      this.tipoClientesCargando = true;
      this.obtenerTipoClientes()
        .then((res) => {
          this.tipoClientes = res.body;
          // this.$v.form.tipoCliente.$model = {
          //     tipo_cliente_id: this.informacionTecnica
          //         .tipo_cliente_id,
          //     nombre_tipo_cliente: this.informacionTecnica
          //         .nombre_tipo_cliente,
          // };
          this.tipoClientesCargando = false;
        })
        .catch((error) => {
          this.tipoClientesCargando = false;
        });
    },
    obtenerAppFlexEnergeticasProyecto() {
      this.appFlexEnergeticasCargando = true;
      this.obtenerAppFlexEnergeticas()
        .then((res) => {
          this.appFlexEnergeticas = res.body;
          // this.$v.form.appFlexEnergetica.$model = {
          //     app_flex_energetica_id: this.informacionTecnica
          //         .app_flex_energetica_id,
          //     nombre_app_flex_energetica: this.informacionTecnica
          //         .nombre_app_flex_energetica,
          // };
          this.appFlexEnergeticasCargando = false;
        })
        .catch((error) => {
          this.appFlexEnergeticasCargando = false;
        });
    },
    submit(e) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.submitted = true;
        this.$v.form.$touch();

        if (this.$v.form.$invalid) {
          this.loading = false;
          resolve(this.$v.form.$invalid);
          return;
        }
        const codigo_lugar_instalacion = this.lugarInstalacion
          .codigo_lugar_instalacion;
        const codigo_proyecto = this.lugarInstalacion.codigo_proyecto;

        const potencia_peak =
          this.$v.form.potenciaPeak.$model != null
            ? this.$v.form.potenciaPeak.$model
            : null;
        const potencia_ac =
          this.$v.form.potenciaAC.$model != null
            ? this.$v.form.potenciaAC.$model
            : null;
        const capacidad_baterias =
          this.$v.form.capacidadBaterias.$model != null
            ? this.$v.form.capacidadBaterias.$model
            : null;
        const capacidad_empalme =
          this.$v.form.capacidadEmpalme.$model != null
            ? this.$v.form.capacidadEmpalme.$model
            : null;
        const tipo_instalacion_id =
          this.$v.form.tipoInstalacion.$model != null
            ? this.$v.form.tipoInstalacion.$model.tipo_instalacion_id
            : null;
        const nombre_tipo_instalacion =
          this.$v.form.tipoInstalacion.$model != null
            ? this.$v.form.tipoInstalacion.$model.nombre_tipo_instalacion
            : null;
        const fase_instalacion =
          this.$v.form.faseInstalacion.$model != null
            ? this.$v.form.faseInstalacion.$model.value
            : null;
        const tipo_cliente_id =
          this.$v.form.tipoCliente.$model != null
            ? this.$v.form.tipoCliente.$model.tipo_cliente_id
            : null;
        const nombre_tipo_cliente =
          this.$v.form.tipoCliente.$model != null
            ? this.$v.form.tipoCliente.$model.nombre_tipo_cliente
            : null;
        const normativa_id =
          this.$v.form.normativa.$model != null
            ? this.$v.form.normativa.$model.normativa_id
            : null;
        const nombre_normativa =
          this.$v.form.normativa.$model != null
            ? this.$v.form.normativa.$model.nombre_normativa
            : null;
        const cliente_electrico =
          this.$v.form.clienteElectrico.$model != null
            ? this.$v.form.clienteElectrico.$model.value
            : null;
        const app_flex_energetica_id =
          this.$v.form.appFlexEnergetica.$model != null
            ? this.$v.form.appFlexEnergetica.$model.app_flex_energetica_id
            : null;
        const nombre_app_flex_energetica =
          this.$v.form.appFlexEnergetica.$model != null
            ? this.$v.form.appFlexEnergetica.$model.nombre_app_flex_energetica
            : null;

        this.crearInformacionTecnica({
          codigo_lugar_instalacion,
          codigo_proyecto,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 201) {
              this.mensaje.variant = "success";
              this.mensaje.texto =
                "Información Técnica creada correctamente!!!";
              this.$emit("on-validate", this.lugarInstalacion);
            }

            if (res.status == 208) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "La Información Técnica ya existe!!!";
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

            this.segundosMensajeActualizacion = this.segundos;
            this.loading = false;
          });

        this.submitted = false;
      });
    },
    resetForm() {
      this.form = {
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    ...proyectoComputed,
    // ...informacionTecnicaComputed,
  },
};
</script>
